import {
  Badge,
  Avatar,
  Descriptions,
  List,
  Typography,
  Button,
  Empty,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import Loader from "../../../common/loader/Loader";
import { HddOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { formatDate } from "../../../../utils/formatDate";
import { LocalDateAsUTC } from "../../../../utils/LocalDateAsUTC";
import { selectCurrentBusiness } from "../../businessesSlice";
import { useAppSelector } from "../../../../app/hooks";
import ExtractLogsFormModal from "./components/ExtractLogsFormModal";

export default function BusinessDevices() {
  const business = useAppSelector(selectCurrentBusiness);
  const devices = business.devices
    ?.map((d) => d)
    .sort((a, b) => {
      return a?.createdAt || "N/A" < b?.createdAt || "N/A" ? -1 : 1;
    });
  const [selectedDevice, setSelectedDevice] = useState<string>("");
  const [extractLogsFormModalShown, setExtractLogsFormModalShown] =
    useState(false);
  return (
    <div style={{ position: "relative", minHeight: "100px" }}>
      {!devices ||
        (devices.length === 0 && (
          <div>
            {" "}
            <Empty description={"No devices found for this business."} />
          </div>
        ))}
      {devices.length > 0 && (
        <>
          <Descriptions title="Devices"></Descriptions>
          <List
            size="small"
            dataSource={business?.devices}
            renderItem={(item) => (
              <List.Item
                key={item.deviceId}
                style={{ padding: "30px 0" }}
                actions={[
                  <Tooltip
                    color="rgb(91 76 252)"
                    placement="right"
                    title={"Extract Logs"}
                  >
                    <Button
                      onClick={() => {
                        setSelectedDevice(item.deviceId);
                        setExtractLogsFormModalShown(true);
                      }}
                      icon={<DownloadOutlined />}
                    ></Button>
                  </Tooltip>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <span>
                      <HddOutlined
                        style={{
                          fontSize: "22px",
                          color:
                            item.type === "Android"
                              ? "#b39ddb" // Light Lavender
                              : item.type === "IOS"
                              ? "#90caf9" // Soft Blue
                              : item.type === "Pax"
                              ? "#b0bec5" // Cool Grey
                              : "#ffcc80", // Muted Peach
                        }}
                      />
                      <br />
                      <span style={{ width: "60px", display: "inline-block" }}>
                        {item?.type}
                      </span>
                    </span>
                  }
                  title={
                    <span style={{ width: "300px", display: "inline-block" }}>
                      {item?.deviceId}
                    </span>
                  }
                  description={`Model: ${item?.model || ""}`}
                ></List.Item.Meta>
                <List.Item.Meta
                  style={{ marginLeft: "60px" }}
                  title={
                    <span>
                      <span style={{ opacity: 0.5 }}> Version:</span>{" "}
                      <span> {item?.buildName || "-"} </span>{" "}
                    </span>
                  }
                  description={`Build Number: ${item?.buildNumber || "-"}`}
                ></List.Item.Meta>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "200px",
                  }}
                >
                  <span>
                    {item.lastOnline &&
                    new LocalDateAsUTC(item?.lastOnline).time >
                      new LocalDateAsUTC().subtractMinutes(30).time ? (
                      <Avatar
                        shape="circle"
                        size="small"
                        style={{
                          backgroundColor: "rgb(26, 162, 26)",
                          opacity: "0.8",
                          boxShadow: "1px 1px 5px grey",
                        }}
                      />
                    ) : (
                      <Avatar
                        size="small"
                        shape="circle"
                        icon={<ClockCircleOutlined />}
                      />
                    )}
                  </span>
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "smaller",
                      color: "gray",
                    }}
                  >
                    {item.lastOnline
                      ? formatDate(new LocalDateAsUTC(item?.lastOnline).rawDate)
                      : "N/A"}
                  </span>
                </div>
              </List.Item>
            )}
          />
        </>
      )}
      <ExtractLogsFormModal
        visible={extractLogsFormModalShown}
        setVisible={setExtractLogsFormModalShown}
        deviceId={selectedDevice}
      />
    </div>
  );
}
