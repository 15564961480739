import { Form, Input, InputNumber } from "antd";
import Select, { BaseOptionType } from "antd/lib/select";

export default function CompanyProfileEditor() {
  const bankFieldLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  const getBusinessTypeOptions = () => {
    return [
      { label: "Sole Trader", value: "soleTrader" },
      { label: "Public Limited Company", value: "publicLimitedCompany" },
      { label: "Private Limited Company", value: "privateLimitedCompany" },
      { label: "Partnership", value: "partnership" },
      { label: "Other", value: "other" },
    ] as BaseOptionType[];
  };
  return (
    <>
      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="Business Name"
        name="businessName"
        labelAlign="left"
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="Business Trade Name"
        name="businessTradeName"
        labelAlign="left"
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="Business Type"
        name="businessType"
        labelAlign="left"
      >
        <Select style={{ minWidth: 190 }} options={getBusinessTypeOptions()} />
      </Form.Item>
      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="VAT"
        name="vat"
        labelAlign="left"
      >
        <InputNumber addonAfter="%" min={0} />
      </Form.Item>
    </>
  );
}
