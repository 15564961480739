import { Button, Form, Input, Modal, Popconfirm, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentBusiness } from "../../../businesses/businessesSlice";
import styles from "./TerminalBusinessAssigner.module.css";
import { CoupleTerminalErrorCode } from "../../../../interfaces/coupleTerminalErrors";
import {
  ICoupleMerchantToPaxTerminalRequest,
  coupleMerchantToPaxTerminalServer,
} from "../../TerminalsApi";
import { coupleMerchantToPaxTerminalThunk } from "../../TerminalsSlice";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { PlatformType } from "../../../../interfaces/paxTerminal.interface";
import {
  selectCouplingPlatformAccessLevel,
  selectIsKlearlyAdmin,
  selectUserPartnerId,
} from "../../../access-control/accountSlice";
interface IPropsTerminalBusinessAssignerEditor {
  visible: boolean;
  setVisible: any;
}

const formFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};

export default function TerminalBusinessAssignerEditor({
  visible,
  setVisible,
}: IPropsTerminalBusinessAssignerEditor) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  const isKlearlyAdmin = useAppSelector(selectIsKlearlyAdmin);
  const couplingPlatformAccess = useAppSelector(
    selectCouplingPlatformAccessLevel
  );
  const partnerId = useAppSelector(selectUserPartnerId);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [errorCode, setErrorCode] = useState<CoupleTerminalErrorCode | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCouplingSuccessful, setIsCouplingSuccessful] = useState(true);
  const [isAlreadyCoupled, setIsAlreadyCoupled] = useState(false);

  useEffect(() => {
    resetState();
  }, [currentBusiness]);

  const resetState = () => {
    setErrorCode(null);
    setErrorMessage(null);
    setIsCouplingSuccessful(false);
    setIsAlreadyCoupled(false);
    form.resetFields();
  };
  const handleSubmit = async (values: {
    serialNumber: string;
    platform?: PlatformType;
  }) => {
    setLoading(true);

    setErrorCode(null);
    setErrorMessage(null);
    setIsAlreadyCoupled(false);
    setIsCouplingSuccessful(false);

    let coupleRequest: ICoupleMerchantToPaxTerminalRequest = {
      businessId: currentBusiness._id,
      serialNumber: values?.serialNumber,
      platform: values?.platform || couplingPlatformAccess,
    };
    if (partnerId) {
      coupleRequest.partnerId = partnerId;
    }

    await dispatch(coupleMerchantToPaxTerminalThunk(coupleRequest))
      .then(({ payload }) => {
        let result = payload as {
          isCoupled: boolean;
          pushId: number;
          error?: { code: CoupleTerminalErrorCode; message: string };
        };

        if (result.error) {
          setErrorCode(result?.error?.code);
          setErrorMessage(result?.error?.message);

          let errorCode = CoupleTerminalErrorCode.CoupledToActiveMerchant;
          form.setFields([
            {
              name: "serialNumber",
              errors: [result?.error?.message],
              value: values?.serialNumber,
            },
          ]);
        } else {
          if (result?.isCoupled) setIsAlreadyCoupled(true);
          else setIsCouplingSuccessful(true);
        }
      })
      .catch((e) => {
        console.log("error", e);
      })
      .finally(() => {
        setLoading(false);
      });

    // let errorCode = CoupleTerminalErrorCode.CoupledToActiveMerchant;
    // let errorMessage =
    //   errorCode === CoupleTerminalErrorCode.CoupledToActiveMerchant
    //     ? "This terminal is coupled to an active merchant."
    //     : errorCode === CoupleTerminalErrorCode.couldNotFindTerminal
    //     ? "No terminal was found with this serial number."
    //     : "";
    // if (errorCode) {
    //   setErrorCode(errorCode);
    //   form.setFields([
    //     {
    //       name: "serialNumber",
    //       errors: [errorMessage],
    //       value: values?.serialNumber,
    //     },
    //   ]);
    // } else {
    //   setVisible(false);
    // }
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={
        <span style={{ opacity: 0.9 }}>
          {"Couple A Terminal To " + currentBusiness?.businessName}
        </span>
      }
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <div className={styles.status_box}>
        {isAlreadyCoupled && (
          <div>
            <ExclamationCircleOutlined style={{ marginLeft: "10px" }} />
            Already coupled
          </div>
        )}
        {isCouplingSuccessful && (
          <div>
            <CheckCircleFilled style={{ color: "green", marginLeft: "10px" }} />{" "}
            Coupling initiated successfully
          </div>
        )}
        {errorCode && <div>Coupling Failed</div>}
      </div>
      {!isCouplingSuccessful && (
        <Form
          onFinish={handleSubmit}
          form={form}
          // style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form.Item
            label="Serial Number"
            name="serialNumber"
            labelAlign="left"
            {...formFieldLayout}
            rules={[{ min: 9 }]}
            tooltip={
              <>
                <span>1. Flip the terminal.</span>
                <br />
                <span>
                  2. Beneath the barcode, find the number following "S/N:"
                </span>
                <img
                  alt="flipped-terminal"
                  src="https://lemonpay-design-templates.s3.eu-central-1.amazonaws.com/flipped-terminal.png"
                  width={250}
                />
              </>
            }
          >
            <Input
              onChange={() => {
                form.setFields([{ name: "serialNumber", errors: [] }]);
              }}
              maxLength={10}
              style={{ maxWidth: "250px", fontSize: "18px" }}
            ></Input>
          </Form.Item>
          <Form.Item
            {...formFieldLayout}
            label="Platform"
            name="platform"
            labelAlign="left"
            hidden={!isKlearlyAdmin}
          >
            <Select
              style={{ maxWidth: "250px" }}
              options={[
                { value: PlatformType.secureRetail, label: "Secure Retail" },
                { value: PlatformType.unitouch, label: "Unitouch" },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Popconfirm
              title={`Assign the terminal to ${currentBusiness.businessName}`}
              onConfirm={form.submit}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{ float: "right", marginTop: "20px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Assign
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      )}
      {isCouplingSuccessful && (
        <>
          <Button
            style={{}}
            type="link"
            onClick={() => {
              navigate("/terminals/");
            }}
          >
            Check out the status
          </Button>
        </>
      )}
      {/* {isAlreadyCoupled && <>Already coupled</>}
      {errorCode && (
        <div>
          <h1>
            {" "}
            <CloseCircleFilled style={{ color: "red" }}></CloseCircleFilled>
            Error Code
          </h1>
          <p>{errorMessage}</p>
        </div>
      )}
      {isCouplingSuccessful && <>Coupling was successful</>} */}
    </Modal>
  );
}
