import { Badge, Button, Descriptions, List, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FileOutlined, DownloadOutlined } from "@ant-design/icons";
import Loader from "../../../common/loader/Loader";
import {
  getBusinessFilesThunk,
  selectCurrentBusiness,
  selectCurrentBusinessFiles,
  selectIsLoadingBusinessFiles,
} from "../../businessesSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { downloadFile } from "../../../../utils/downloadFile";

export default function BusinessFiles() {
  const business = useAppSelector(selectCurrentBusiness);
  const businessFiles = useAppSelector(selectCurrentBusinessFiles);
  const isLoadingBusinessFiles = useAppSelector(selectIsLoadingBusinessFiles);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (business) {
      fetchBusinessFiles();
    }
  }, [business]);

  const fetchBusinessFiles = async () => {
    if (!business?._id) return;

    await dispatch(
      getBusinessFilesThunk({
        email: business.rootUser.email,
        businessRegistrationNumber: business.businessRegistrationNumber,
        businessName: business.businessName,
        iban: business.bankAccountIban,
        name: business.rootUser.name,
      })
    );
  };

  return (
    <div style={{ position: "relative", minHeight: "100px" }}>
      {!isLoadingBusinessFiles && businessFiles && businessFiles.length > 0 && (
        <>
          <Descriptions title="Additional Files"></Descriptions>

          <List
            size="small"
            dataSource={businessFiles}
            renderItem={(item) => (
              <List.Item key={item.fileName}>
                <List.Item.Meta
                  avatar={
                    <>
                      <FileOutlined />
                      <br />
                      {item.fileExtension}
                    </>
                  }
                  title={
                    <span>{item?.fileName || <Badge count={"Missing"} />}</span>
                  }
                ></List.Item.Meta>

                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    downloadFile(item.file, item.fileName, item.fileExtension);
                  }}
                  disabled={!item.fileName}
                ></Button>
              </List.Item>
            )}
          />
        </>
      )}
      <div>
        {isLoadingBusinessFiles && <Loader text="Fetching Business Files" />}
      </div>
    </div>
  );
}
