import { Button, Card, Form, Input, Select, Table, Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { selectCurrentBusiness } from "../../../businessesSlice";
import { useEffect, useMemo, useState } from "react";

import {
  getPartnersThunk,
  selectPartners,
} from "../../../../partners/partnersSlice";
import Loader from "../../../../common/loader/Loader";
import {
  IBucketGroups,
  IPurchPriceProfile,
} from "../../../../../interfaces/partner.interface";
import BucketGroupValue from "../../../../partner-deals/components/partner-deals-table/subcomponents/bucket-group-value/BucketGroupValue";
import { getBucketGroupName } from "../../../utils/getBucketGroupName";

const partnerFieldLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
};

type MerchantBucketRange = {
  name: string;
  isInRange: string;
  klearlyMarkup: string;
  partnerMarkup: string;
};
export default function PartnerMapper() {
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  const partners = useAppSelector(selectPartners);
  const [selectedPartnerId, setSelectedPartnerId] = useState<string>();
  const [purchPriceProfiles, setPurchPriceProfiles] =
    useState<IPurchPriceProfile[]>();
  const [selectedPurchProfile, setSelectedPurchProfile] =
    useState<IPurchPriceProfile>();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedPartnerId(undefined);
    setPurchPriceProfiles(undefined);
    setSelectedPurchProfile(undefined);
    if (!partners || partners?.length === 0) {
      getAllPartners();
    }
  }, [currentBusiness]);

  const getAllPartners = async () => {
    setIsLoading(true);
    await dispatch(getPartnersThunk());
    setIsLoading(false);
  };

  const handlePartnerSelection = (partnerId: string) => {
    setSelectedPartnerId(partnerId);
    let partner = partners.find((p) => p._id === partnerId);
    setPurchPriceProfiles(partner?.purchPriceProfiles);
    let defaultPricing = partner?.purchPriceProfiles.find(
      (pr) => pr.name === "default"
    );
    setSelectedPurchProfile(defaultPricing);
  };
  const handlePurchPriceSelection = (purchPriceProfileId: string) => {
    let purchPriceProfile = purchPriceProfiles?.find(
      (p) => p._id === purchPriceProfileId
    );
    if (purchPriceProfile) setSelectedPurchProfile(purchPriceProfile);
  };

  const getMerchantBucketRange = (selectedPurchProfile: IPurchPriceProfile) => {
    let results: {
      name: string;
      currentValue: string;
      isInRange: boolean;
      klearlyMarkup: string | null;
      partnerMarkup: string | null;
    }[] = [];

    let merchantBuckets = currentBusiness.merchantServiceChargeFees;

    merchantBuckets?.forEach((m) => {
      let bucketGroupName = getBucketGroupName(m.name);
      let bucketGroup = selectedPurchProfile?.bucketGroups[bucketGroupName];
      if (bucketGroup?.value) {
        let isInRange =
          m.value >= bucketGroup?.value && m.value <= bucketGroup?.max;
        let klearlyMarkup = isInRange
          ? formatValue(bucketGroup.value, bucketGroup.valueType)
          : null;
        let partnerMarkup = isInRange
          ? formatValue(m.value - bucketGroup.value, bucketGroup.valueType)
          : null;
        results.push({
          name: m.name,
          currentValue: formatValue(m.value, m.valueType),
          isInRange,
          klearlyMarkup,
          partnerMarkup,
        });
      }
    });

    return results;
  };

  const formatValue = (value: number, type: "Fixed" | "Percentage") => {
    return value.toFixed(2) + (type === "Fixed" ? "€" : "%");
  };

  const merchantBucketRanges = useMemo(() => {
    if (selectedPurchProfile)
      return getMerchantBucketRange(selectedPurchProfile);
  }, [selectedPurchProfile]);

  return (
    <>
      {!isLoading && (
        <>
          <Form.Item
            label="Partner"
            name={["partnerId"]}
            wrapperCol={{ ...partnerFieldLayout.wrapperCol }}
            labelCol={{ ...partnerFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Select
              disabled={currentBusiness?.partnerId ? true : false}
              style={{ width: 200 }}
              onChange={handlePartnerSelection}
              options={partners?.map((p) => ({
                label: p.name,
                value: p._id,
                key: p._id,
              }))}
            />
          </Form.Item>
          {(selectedPartnerId ||
            currentBusiness?.partnerPurchPriceProfileId) && (
            <Form.Item
              label="Pricing Profile"
              name={["partnerPurchPriceProfileId"]}
              wrapperCol={{ ...partnerFieldLayout.wrapperCol }}
              labelCol={{ ...partnerFieldLayout.labelCol }}
              labelAlign="left"
              rules={[
                {
                  validator: async (_, profile) => {
                    if (
                      merchantBucketRanges &&
                      merchantBucketRanges.findIndex((r) => !r.isInRange) > 0
                    )
                      return Promise.reject(
                        new Error(`"Pricing is out of range!`)
                      );
                  },
                },
              ]}
            >
              <Select
                disabled={
                  currentBusiness?.partnerPurchPriceProfileId ? true : false
                }
                style={{ width: 200 }}
                onChange={handlePurchPriceSelection}
                options={purchPriceProfiles?.map((p) => ({
                  label: p.name,
                  value: p._id,
                  key: p._id,
                }))}
              />
            </Form.Item>
          )}

          {selectedPurchProfile?._id && (
            <div>
              <h3>Partner Profile Range</h3>
              <br />
              <BucketGroupValue
                label="Domestic Debit Card"
                value={
                  selectedPurchProfile.bucketGroups.domesticDebitCards.value
                }
                max={selectedPurchProfile.bucketGroups.domesticDebitCards.max}
                valueType={
                  selectedPurchProfile.bucketGroups.domesticDebitCards.valueType
                }
              />
              <BucketGroupValue
                label="European"
                value={selectedPurchProfile.bucketGroups.european.value}
                max={selectedPurchProfile.bucketGroups.european.max}
                valueType={selectedPurchProfile.bucketGroups.european.valueType}
              />
              <BucketGroupValue
                label="International & Commericial"
                value={
                  selectedPurchProfile.bucketGroups.internationalAndCommercial
                    .value
                }
                max={
                  selectedPurchProfile.bucketGroups.internationalAndCommercial
                    .max
                }
                valueType={
                  selectedPurchProfile.bucketGroups.internationalAndCommercial
                    .valueType
                }
              />

              <br />
              <br />
              <h3>Merchant Current Pricing</h3>
              {merchantBucketRanges &&
                merchantBucketRanges.findIndex((r) => !r.isInRange) > 0 && (
                  <>
                    <span
                      style={{
                        color: "red",
                        fontWeight: 600,
                      }}
                    >
                      Pricing is not in range ! Please adjust pricing both in
                      Rapyd and in Klearly.
                    </span>
                    <br />
                  </>
                )}
              <br />

              <Table
                dataSource={merchantBucketRanges}
                pagination={false}
                columns={[
                  {
                    title: "",
                    dataIndex: "name",
                    render: (v, record) => (
                      <span
                        style={{
                          color: record?.isInRange ? "inherit" : "red",
                        }}
                      >
                        {v}
                      </span>
                    ),
                  },
                  {
                    title: "current",
                    dataIndex: "currentValue",
                    render: (v, record) => (
                      <span
                        style={{
                          color: record?.isInRange ? "inherit" : "red",
                        }}
                      >
                        {v}
                      </span>
                    ),
                  },
                  {
                    title: "K",
                    dataIndex: "klearlyMarkup",
                  },
                  {
                    title: "P",
                    dataIndex: "partnerMarkup",
                  },
                ]}
              />
              <br />
            </div>
          )}
        </>
      )}

      {isLoading && <Loader text="Getting All Partners" />}
    </>
  );
}
