import React, { useEffect, useState } from "react";
import { IScheduledPush } from "../../../../../../interfaces/scheduledPush.interface";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  notification,
} from "antd";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { LocalDateAsUTC } from "../../../../../../utils/LocalDateAsUTC";

interface IPropsTargetAttributesForm {
  scheduledPush: IScheduledPush;
  onSubmit: (props: IScheduledPush) => void;
}

const NotificationFieldLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

export const ROLE_OPTIONS_MAP = {
  USER: "Employees",
  ADMIN: "Admins",
  ROOT: "Owners",
};

export const STATUS_OPTIONS_MAP = {
  verified: "Verified",
  pendingSubmission: "Pending Submission",
  unverified: "Declined",
};

export const DEVICE_OPTIONS_MAP = {
  Pax: "Pax",
  Sunmi: "Sunmi",
  Android: "Android",
  IOS: "IOS",
};

export const KLEARLY_VERSIONS = [
  " 1.15.3-y",
  "1.15.4-lite-y",
  "1.15.3-lite-rapyd",
  "1.15.1-lite-rapyd",
  "1.15.1-y",
  "1.15.1-lite-y",
  "1.14.0-ym",
  "1.13.1-lite-ym",
  "1.13.2-lite-ym",
  "1.12.9-lite-rapyd",
  "1.13.3-lite-ym",
  "1.13.2-lite-rapyd",
  "1.11.2-ym",
  "1.13.9-lite-ym",
  "1.10.21-ym",
  "1.10.22-ym",
  "1.13.4-lite-ym",
  "1.11.0-ym",
  "1.13.1-lite-rapyd",
  "1.0.1",
  "1.12.10-lite-rapyd",
  "1.0.2",
  "1.13.5-lite-rapyd",
  "1.12.10-lite-ym",
  "1.11.2-y",
  "1.14.0-y",
  "1.0.8",
  "1.11.3-y",
  "1.0.9",
  "1.10.20-ym",
  "1.11.0-y",
  "1.14.0-lite-ym",
  "1.13.8-lite-ym",
  "1.0.7",
  "1.11.1-y",
  "1.13.7-lite-ym",
  "1.12.13-lite-rapyd",
  "1.11.1-ym",
  "1.13.3-lite-rapyd",
  "1.10.22-y",
  "1.0.6",
  "1.10.21-y",
  "1.10.20-y",
  "1.14.0-lite-rapyd",
  "1.11.4-y",
  "1.13.6-lite-ym",
  "1.13.9-lite-y",
  "1.0.3",
  "1.0.0",
  "1.13.0-lite-ym",
  "1.12.11-lite-ym",
  "1.0.5",
  "1.12.10-full-rapyd",
  "1.0.4",
];

export default function TargetAttributesForm({
  scheduledPush,
  onSubmit,
}: IPropsTargetAttributesForm) {
  const [form] = Form.useForm();
  const [isCustomLastActive, setIsCustomLastActive] = useState(
    scheduledPush.targetAttributes?.active ? true : false
  );
  useEffect(() => {
    if (scheduledPush) {
      form.setFieldsValue({
        targetAttributes: {
          ...scheduledPush.targetAttributes,
          lastActive: scheduledPush.targetAttributes?.lastActive
            ? moment(scheduledPush.targetAttributes.lastActive)
            : "",
        },
      });
    }
  }, []);

  const handleActiveUserChange = (value: RadioChangeEvent) => {
    setIsCustomLastActive(value?.target?.value ? true : false);
  };

  const handleSubmit = (
    values: IScheduledPush & {
      targetAttributes: { lastActive?: moment.Moment };
    }
  ) => {
    if (values.targetAttributes?.lastActive)
      //@ts-ignore
      values.targetAttributes.lastActive =
        values.targetAttributes.lastActive.toISOString();

    onSubmit(values);
  };
  const disabledDate: RangePickerProps["disabledDate"] = (selected) => {
    return selected.toISOString() >= new LocalDateAsUTC().ISO;
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{ width: "100%" }}
        layout="vertical"
      >
        <Form.Item
          name={["targetAttributes", "status"]}
          label={"Status"}
          rules={[{ required: true, message: "Status required" }]}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Select
            showArrow
            mode="multiple"
            allowClear
            style={{ width: "500px" }}
            placeholder="Please select"
            // defaultValue={["verified"]}
            options={Object.entries(STATUS_OPTIONS_MAP).map(([k, v]) => ({
              label: v,
              value: k,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={["targetAttributes", "role"]}
          label={"Role"}
          rules={[{ required: true, message: "Role required" }]}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Select
            showArrow
            mode="multiple"
            allowClear
            style={{ width: "500px" }}
            placeholder="Please select"
            defaultValue={["ADMIN", "ROOT"]}
            options={Object.entries(ROLE_OPTIONS_MAP).map(([k, v]) => ({
              label: v,
              value: k,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={["targetAttributes", "deviceType"]}
          label={"Device Type"}
          rules={[{ required: true, message: "Device type required" }]}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Select
            showArrow
            mode="multiple"
            allowClear
            style={{ width: "500px" }}
            placeholder="Please select"
            defaultValue={["Pax", "Sunmi", "Android", "IOS"]}
            options={Object.entries(DEVICE_OPTIONS_MAP).map(([k, v]) => ({
              label: v,
              value: k,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={["targetAttributes", "version"]}
          label={"Klearly Version"}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Select
            showArrow
            mode="multiple"
            allowClear
            style={{ width: "500px" }}
            placeholder="Select version (optional)"
            defaultValue={[]}
            options={KLEARLY_VERSIONS.map((v) => ({
              label: v,
              value: v,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={["targetAttributes", "active"]}
          rules={[{ required: true, message: "Active status required" }]}
          label={"Only Active Users"}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Radio.Group onChange={handleActiveUserChange}>
            <Radio.Button value={true}>{"True"}</Radio.Button>
            <Radio.Button value={false}>{"False"}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {isCustomLastActive && (
          <Form.Item
            name={["targetAttributes", "lastActive"]}
            rules={[{ required: true, message: "Last active date required" }]}
            label={"Last Date"}
            help="Defaults to last 30 days"
            wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
            labelCol={{ ...NotificationFieldLayout.labelCol }}
            labelAlign="left"
            style={{ marginBottom: "10px" }}
          >
            <DatePicker
              showTime
              format="DD-MM-YYYY HH:mm:ss"
              disabledDate={disabledDate}
              //@ts-ignore
              // disabledTime={disabledTime}
              showSecond={false}
              showNow={false}
            />
          </Form.Item>
        )}

        <Form.Item
          name={["targetAttributes", "isTest"]}
          label={"Is Test"}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Radio.Group>
            <Radio.Button value={true}>{"True"}</Radio.Button>
            <Radio.Button value={false}>{"False"}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
