import { Button, DatePicker, Form, Input, Modal, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IBusinessFull } from "../../../../interfaces/businessFull.interface";
import {
  editBusinessThunk,
  selectCurrentBusiness,
} from "../../../businesses/businessesSlice";
import { selectSalesReps } from "../../SalesSlice";
import { LocalDateAsUTC } from "../../../../utils/LocalDateAsUTC";
import moment from "moment";
import { selectIsKlearlySalesSuperAdmin } from "../../../access-control/accountSlice";
import SalesRepIcon from "../Sales-Rep-Icon/SalesRepIcon";

interface IPropsBusinessEditorModel {
  visible: boolean;
  setVisible: any;
}

const salesFormFieldLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const dateFormat = "DD/MM/YYYY";
export default function SalesBusinessEditorModal({
  visible,
  setVisible,
}: IPropsBusinessEditorModel) {
  const [loading, setLoading] = useState(false);
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  const salesReps = useAppSelector(selectSalesReps);
  const isSalesSuperAdmin = useAppSelector(selectIsKlearlySalesSuperAdmin);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [saleRepSelectionOptions, setSaleRepSelectionOptions] = useState([
    ...salesReps,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      salesRepresentative: currentBusiness.salesRepresentative,
      salesRepresentativeExpDate: currentBusiness.salesRepresentativeExpDate
        ? moment(currentBusiness.salesRepresentativeExpDate)
        : null,
      salesRepresentative2: currentBusiness.salesRepresentative2,
      salesRepresentative2ExpDate: currentBusiness.salesRepresentative2ExpDate
        ? moment(currentBusiness.salesRepresentativeExpDate)
        : null,
      salesBusinessDeveloper: currentBusiness.salesBusinessDeveloper
        ? moment(currentBusiness.salesBusinessDeveloper)
        : null,
      salesBusinessDeveloperExpDate:
        currentBusiness.salesBusinessDeveloperExpDate
          ? moment(currentBusiness.salesBusinessDeveloperExpDate)
          : null,
    });

    // setSaleRepSelectionOptions(
    //   getFilteredSalesReps([
    //     currentBusiness.salesRepresentative,
    //     currentBusiness.salesRepresentative2,
    //     currentBusiness.salesBusinessDeveloper,
    //   ])
    // );

    return () => {
      form.resetFields();
      setSaleRepSelectionOptions(salesReps);
    };
  }, [currentBusiness]);

  const getExpirationDate = (salesRep: string, verificationDate: string) => {
    let monthsToExp =
      salesReps.find((r) => r.value === salesRep)?.monthsToExp || 12;
    return new LocalDateAsUTC(verificationDate).addMonths(monthsToExp).ISO;
  };
  const handleSubmit = async (values: Partial<IBusinessFull>) => {
    setLoading(true);

    //if duplicate sales reps are selected then show error message

    if (
      (values.salesRepresentative === values.salesRepresentative2 &&
        values.salesRepresentative) ||
      (values.salesRepresentative === values.salesBusinessDeveloper &&
        values.salesBusinessDeveloper) ||
      (values.salesRepresentative2 === values.salesBusinessDeveloper &&
        values.salesBusinessDeveloper)
    ) {
      //set error in form
      form.setFields([
        {
          name: "salesRepresentative",
          errors: ["Duplicate Sales Reps selected"],
        },
        {
          name: "salesRepresentative2",
          errors: ["Duplicate Sales Reps selected"],
        },
        {
          name: "salesBusinessDeveloper",
          errors: ["Duplicate Sales Reps selected"],
        },
      ]);
      setLoading(false);
      return;
    } else {
      //remove errors
      form.setFields([
        {
          name: "salesRepresentative",
          errors: [],
        },
        {
          name: "salesRepresentative2",
          errors: [],
        },
        {
          name: "salesBusinessDeveloper",
          errors: [],
        },
      ]);
    }

    if (
      values.salesRepresentative &&
      !currentBusiness?.salesRepresentativeExpDate
    ) {
      values.salesRepresentativeExpDate = getExpirationDate(
        values.salesRepresentative,
        currentBusiness.verificationDate || currentBusiness.createdAt
      );
    }
    if (
      values.salesRepresentative2 &&
      !currentBusiness?.salesRepresentative2ExpDate
    ) {
      values.salesRepresentative2ExpDate = getExpirationDate(
        values.salesRepresentative2,
        currentBusiness.verificationDate || currentBusiness.createdAt
      );
    }
    if (
      values.salesBusinessDeveloper &&
      !currentBusiness?.salesBusinessDeveloperExpDate
    ) {
      values.salesBusinessDeveloperExpDate = getExpirationDate(
        values.salesBusinessDeveloper,
        currentBusiness.verificationDate || currentBusiness.createdAt
      );
    }
    // return;
    await dispatch(
      editBusinessThunk({ _id: currentBusiness._id, updatedFields: values })
    );

    setLoading(false);
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleSelectionChange = (value: string, salesRep: SalesRepType) => {
    // setSaleRepSelectionOptions(getFilteredSalesReps([value]));
  };

  const getFilteredSalesReps = (salesRepsToExclude: string[]) => {
    return saleRepSelectionOptions.filter(
      (r) => !salesRepsToExclude.includes(r.value)
    );
  };

  type SalesRepType =
    | "salesRepresentative"
    | "salesRepresentative2"
    | "salesBusinessDeveloper";

  const SalesRepsForms: {
    title: string;
    salesRepFieldName: SalesRepType;
    salesRepExpFieldName: string;
  }[] = [
    {
      title: "Account Manager 1",
      salesRepFieldName: "salesRepresentative",
      salesRepExpFieldName: "salesRepresentativeExpDate",
    },
    {
      title: "Account Manager 2",
      salesRepFieldName: "salesRepresentative2",
      salesRepExpFieldName: "salesRepresentative2ExpDate",
    },
    {
      title: "Business Developer",
      salesRepFieldName: "salesBusinessDeveloper",
      salesRepExpFieldName: "salesBusinessDeveloperExpDate",
    },
  ];

  const isDisabled = (salesRep: SalesRepType) => {
    if (currentBusiness[salesRep] && !isSalesSuperAdmin) {
      return true;
    }
  };
  return (
    <Modal
      visible={visible}
      title={"Assign - " + currentBusiness?.businessName}
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <Form onFinish={handleSubmit} form={form}>
        <Tabs tabPosition="left">
          {SalesRepsForms.map((salesForm) => (
            <Tabs.TabPane
              tab={
                <>
                  <SalesRepIcon
                    type={salesForm.salesRepFieldName}
                    label={salesForm.title}
                  />
                </>
              }
              key={"item-" + salesForm.salesRepFieldName}
            >
              <Form.Item
                label="Name"
                name={salesForm.salesRepFieldName}
                labelCol={salesFormFieldLayout.labelCol}
                labelAlign="left"
              >
                <Select
                  disabled={isDisabled(salesForm.salesRepFieldName)}
                  onChange={(value) =>
                    handleSelectionChange(value, salesForm.salesRepFieldName)
                  }
                  options={saleRepSelectionOptions}
                />
              </Form.Item>

              <Form.Item
                name={salesForm.salesRepExpFieldName}
                label="Expiration Date"
                hidden={!isSalesSuperAdmin}
                labelCol={salesFormFieldLayout.labelCol}
                labelAlign="left"
                help="Default: 13 months from verification date"
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Tabs.TabPane>
          ))}
        </Tabs>
        <Form.Item>
          <Button
            style={{ float: "right" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
