import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getPartnersThunk,
  selectPartners,
  setCurrentPartner,
} from "../../partnersSlice";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, List, Menu, MenuProps, Skeleton } from "antd";
import { EditOutlined, EuroOutlined, TeamOutlined } from "@ant-design/icons";
import styles from "./PartnersTable.module.css";
import Loader from "../../../common/loader/Loader";
import { selectUser } from "../../../access-control/accountSlice";
import { IPartner } from "../../../../interfaces/partner.interface";
import EditPartnerForm from "../edit-partner-form/EditPartnerForm";
import CreatePartnerReportFormModal from "../create-partner-report-form/CreatePartnerReportForm";
import PartnerTeamMemberModal from "../partner-team-member-modal/PartnerTeamMemberModal";

export default function PartnersTable() {
  const [loading, setLoading] = useState(false);
  const partners = useAppSelector(selectPartners);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [editPartnerFormVisible, setEditPartnerFormVisible] = useState(false);
  const [createPartnerReportFormVisible, setCreatePartnerReportFormVisible] =
    useState(false);
  const [partnerTeamMemberModalVisible, setPartnerTeamMemberModalVisible] =
    useState(false);

  useEffect(() => {
    getPartners();
  }, []);

  async function getPartners() {
    setLoading(true);
    await dispatch(getPartnersThunk());
    setLoading(false);
  }

  function handleEditPartner(partnerId: string) {
    dispatch(setCurrentPartner(partnerId));
    setEditPartnerFormVisible(true);
  }
  function handleCreatePartnerReport(partnerId: string) {
    dispatch(setCurrentPartner(partnerId));
    setCreatePartnerReportFormVisible(true);
  }
  function handlePartnerTeamMemberModal(partnerId: string) {
    dispatch(setCurrentPartner(partnerId));
    setPartnerTeamMemberModalVisible(true);
  }

  return (
    <div>
      {!loading && partners?.length > 0 && (
        <List
          itemLayout="horizontal"
          className={styles.list}
          dataSource={partners}
          renderItem={(item) => (
            <List.Item
              className={styles.list_item}
              actions={[
                <>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          onClick={() => {
                            handleEditPartner(item._id);
                          }}
                          icon={<EditOutlined />}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            handleCreatePartnerReport(item._id);
                          }}
                          icon={<EuroOutlined />}
                        >
                          Markup Report
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            handlePartnerTeamMemberModal(item._id);
                          }}
                          icon={<TeamOutlined />}
                        >
                          Team Members
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomLeft"
                  >
                    <Button type="default">Actions</Button>
                  </Dropdown>
                  {/* <Button> {">"}</Button> */}
                </>,
              ]}
            >
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  title={item.name}
                  description={
                    <div>
                      <span>{item.email}</span>
                      <br />
                      <span>{`+${item.phone.countryCode} ${item.phone.number}`}</span>
                      <br />
                    </div>
                  }
                />
                <div> </div>
              </Skeleton>
            </List.Item>
          )}
        />
      )}

      {loading && <Loader text="Getting Partners"></Loader>}
      {editPartnerFormVisible && (
        <EditPartnerForm
          visible={editPartnerFormVisible}
          setVisible={setEditPartnerFormVisible}
        />
      )}
      {createPartnerReportFormVisible && (
        <CreatePartnerReportFormModal
          visible={createPartnerReportFormVisible}
          setVisible={setCreatePartnerReportFormVisible}
        />
      )}
      {partnerTeamMemberModalVisible && (
        <PartnerTeamMemberModal
          visible={partnerTeamMemberModalVisible}
          setVisible={setPartnerTeamMemberModalVisible}
        />
      )}
    </div>
  );
}
